.App {
}
@font-face {
  font-family: FuturaPT;
  src: url("/src/assets/fonts/FuturaPTBook.otf");
}

@font-face {
  font-family: FuturaPTBold;
  src: url("assets/fonts/FuturaPTBold.otf");
}

body {
  font-family: "FuturaPT" !important;
}
h1,
h2,
h3 {
  font-family: "FuturaPTBold" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.externalClass {
  position: relative;
  top: 50%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagefooter {
  background: black;
  color: white;
}

.pagefooter .main-footer {
  padding: 120px 0px 50px 0px;
}
.footer-sec {
  text-align: left;
}

.footer-sec a {
  color: white;
}

.footer-sec li {
  list-style-type: none;
}
.footer-sec h2 {
  margin-bottom: 40px;
}
.footer-sec ul {
  padding-left: 0px;
}

.footer-social-links a img {
  height: 45px;
  display: inline-block;
  margin-right: 10px;
}
.pagefooter .copyright {
  text-align: left;
  padding: 40px 0px;
  border-top: 1px solid;
}
.navbar-nav {
  margin-left: auto;
}
li.nav-item {
  padding: 0px 12px;
}
.navbar-nav .nav-link {
  /* padding: 0 20px; */
  font-size: 18px;
  font-weight: bold;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(172 209 98);
}
.logo-main {
  height: 100px;
}

.left-loc-div {
  height: 500px;
  background: linear-gradient(180deg, #cc727100 0%, #2c0000 100%) 0% 0%
      no-repeat padding-box,
    url("./assets/images/map.png");
  display: flex;
  align-items: flex-end;
  padding: 0px;
  mix-blend-mode: multiply;
  border-radius: 10px;
  /* border: 1px solid; */
  margin-bottom: 30px;
  color: white;
  position: relative;
  /* border-radius: 8px; */
  overflow: hidden;
}
.left-loc-div .content h3 {
  font-size: 22px;
  margin-bottom: 40px;
}

.left-loc-div .content {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9999;
  height: 42%;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: flex-end;
  /* background: rgb(163 3 3 / 30%); */
  background: rgb(45, 1, 3, 1);
  background: linear-gradient(
    0deg,
    rgba(45, 1, 3, 1) 0%,
    rgba(242, 238, 232, 1) 130%
  );
  padding: 20px;
  opacity: 0.9;
}

.left-loc-div .content p {
  font-size: 12px;
}
.pcaa-link {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  z-index: 2;
  cursor: pointer;
  padding: 0 13px;
  font-weight: bold;
  text-decoration: underline;
  font-family: "FuturaPTBold";
}
.pcaa-link::before {
  transition: all 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  position: absolute;
  height: 2.4rem;
  display: block;
  width: 2.4rem;
  content: "";
  background-position: -100% 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #c0d751;
  border-radius: 2.09375rem;
  z-index: -1;
  left: 0rem;
  top: 50%;
}

.pcaa-link:hover {
  color: #fff;
  transition: all 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.pcaa-link:hover::before {
  background-position: 0% 100%;
  width: 100%;
}
.left-waether-div {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 20px;
}
.head-div {
  background: #d4ebde 0% 0% no-repeat padding-box;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
}
.head-div h3 {
  font-size: 18px;
  width: 80%;
}
.head-div h3 {
  font-size: 14px;
}
.left-waether-div .body-div {
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
}
.left-waether-div .body-div table tr td:first-child {
  font-size: 14px;
  color: #4d5d74;
}

.left-waether-div .body-div table tr td:nth-child(2) {
  font-size: 14px;
  color: #4d5d74;
  font-weight: 600;
}
.naviagation-row {
  padding: 50px 0px;
}
.location-breadcum {
  margin-bottom: 40px;
}
.location-breadcum .station {
  background: #d4ebde;
  padding: 5px 5px 5px 12px;
}
span.lastupdate {
  font-weight: 700;
  color: #4d5d74;
}

.top-detail-row {
  background: #fe6a69;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
  display: flex;
  align-items: center;
  color: white;
}

.aqi-value-box {
  background-color: inherit;
  background: linear-gradient(rgb(0 0 0 / 9%), rgb(255 255 255 / 17%));
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  margin-right: 40px;
}
.aqi-value-box img {
  height: 30px;
}
.aqi-value-box p {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.aqi-text h6 {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.1px;
}
.aqi-text {
  margin-right: 40px;
  width: 60%;
}
.aqi-icon i {
  font-size: 60px;
}

.aqichart-main-div {
  /* background: #FAFAFA; */
  margin: 40px 0px;
}
.overview-stats-div {
  padding: 35px;
  background: #fafafa;
}
.recomendatios-main-div .heading,
.aqichart-main-div .heading,
.overview-stats-div .heading {
  color: #41aef2;
  font-weight: 600;
}
.recomendatios-main-div text,
.aqichart-main-div .text,
.overview-stats-div .text {
  color: #0d0d0d;
  font-weight: 600;
}
.recomendatios-main-div {
  padding: 0 35px 15px 35px;
  background: #fafafa;
}
.overview-stats-div .col {
  border: 1px solid #dadada;
  text-align: center;
  padding: 10px;
}
.leaflet-marker-icon .custom-cluster-icon {
  background-repeat: no-repeat;
  height: 16px;
  min-width: 24px;
  display: flex;
  background-size: contain;
  color: #fff;
  position: relative;
  background-position: center;
  padding: 2px 13px;
  border-radius: 2px;
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  font-weight: bold;
  top: -6px;
  box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 60%);
}
.leaflet-div-icon:after {
  content: "";
  width: 2px;
  height: 16px;
  display: inline-block;
  position: absolute;
  right: -4px;
  bottom: -16px;
  background: #144651;
}
.bg-good,
.top-detail-row-good,
.custom-cluster-icon-good,
.popuphead-bg-good {
  background: #abd162 !important;
}
.bg-moderate,
.top-detail-row-moderate,
.custom-cluster-icon-moderate,
.popuphead-bg-moderate {
  background: #f7d460;
}

.bg-unhealthy-for,
.top-detail-row-unhealthy-for,
.custom-cluster-icon-unhealthy-for,
.popuphead-bg-unhealthy-for {
  background: #f68e49;
}
.bg-unhealthy,
.top-detail-row-unhealthy,
.custom-cluster-icon-unhealthy,
.popuphead-bg-unhealthy {
  background: #f65e5f;
}

.bg-v-unhealthy,
.top-detail-row-v-unhealthy,
.custom-cluster-icon-v-unhealthy,
.popuphead-bg-v-unhealthy {
  background: #a070b6;
}
.bg-hazardous,
.top-detail-row-hazardous,
.custom-cluster-icon-hazardous,
.popuphead-bg-hazardous {
  background: #a06a7b;
}

.leaflet-div-icon {
  background: transparent;
  border-color: transparent !important;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border-radius: 6px;
  width: 300px;
  height: 420px;
}
.popup-title {
  color: #0d0d0d;
  font-weight: 600;
  /* font-size: 20px; */
  font-family: "FuturaPT";
}
.popup-main-div {
  font-family: "FuturaPT";
}
.popup-aqi-text p {
  margin: 0 !important;
}
.popup-aqi-text {
  color: white;
  margin-left: 10px;
}
.popup-aqi-icon i {
  font-size: 40px;
  font-size: 30px;
  color: white;
}
.popup-head-div {
  display: flex;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  padding: 10px 20px;
  padding: 5px 15px;
}
.popup-time-div {
  text-align: center;
  margin-top: 10px;
  color: #6f6f6f;
}
.popup-time-div p {
  margin: 0 !important;
}
.sponser-log-div,
.left-locs-list-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4ebde;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}
.sponser-log-div h5,
.left-locs-list-div h5 {
  font-weight: 600;
}
.loc-list {
  padding: 0;
  list-style: none;
}
.sponser-log-div img {
  width: 100%;
  max-width: 230px;
}
.recomendation {
  margin: 20px 0px;
  display: flex;
}

.recomendation img {
  height: 30px;
  margin-right: 10px;
  width: 30px;
  object-fit: contain;
}
.recomendation p {
  color: #4d5d74;
  font-size: 14px;
}

.select-search-container {
  width: 100% !important;
  position: absolute !important;
  z-index: 3;
  top: 30%;
  display: flex;
  justify-content: center;
}
.select-search-select {
  width: 30% !important;
  right: auto !important;
  border-radius: 20px !important;
  left: auto !important;
}

.select-search-value input {
  border-radius: 20px;
}
.select-search-value {
  width: 30%;
}
.leaflet-container {
  z-index: 2;
}
.color-bar-main-div div span {
  font-size: 12px;
}
.color-bar-main-div div {
  display: inline-block;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  text-align: center;
  padding: 5px 10px;
}
.color-container-div {
  position: relative;
}
.color-bar-main-div {
  display: flex;
  width: 100%;
  justify-content: center;
  /* position: absolute; */
  /* top: -60px; */
  z-index: 200;
}
.home-colorbag .color-bar-main-div {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  top: -60px;
  z-index: 9999;
}
.color-bar-main-div .Good {
  background: #abd162;
  border-radius: 20px 0px 0px 20px;
  color: #4d5d74;
  border: 2px solid white;
}
.color-bar-main-div .moderate {
  background: #f7d460;
  color: #4d5d74;
}
.color-bar-main-div .unhealty-for {
  background: #f68e49;
  color: #0d0d0d;
}

.color-bar-main-div .unhealthy {
  background: #f65e5f;
  color: white;
}
.color-bar-main-div .very-unhealthy {
  background: #a070b6;
  color: white;
}
.color-bar-main-div .hazardous {
  background: #a06a7b;
  border-radius: 0px 20px 20px 0px;
  color: white;
  border: 2px solid white;
}

.current-update-table {
  width: 100%;
  text-align: center;
}
.current-update-table p {
  margin: 0;
}
.current-update-table .headerrow {
  background: #dadada;
  border-radius: 10px 0px 0px 10px;
}
.current-update-table .headerrow th:first-child {
  border-radius: 10px 0px 0px 0px;
}
.current-update-table .headerrow th:last-child {
  border-radius: 0px 10px 0px 0px;
}
.current-update-table .headerrow th {
  text-align: inherit;
  width: 33%;
  padding: 8px;
}

.bodyrow td {
  text-align: inherit;
  width: 33%;
  padding: 8px;
  border: 1px solid #dadada;
  border-radius: 10px;
}
.space-div {
  padding: 15px 0px;
}
.popup-chart-div {
  display: flex;
  height: 150px;
  overflow: hidden;
}
.popup-chart-div svg {
  height: 200px;
}

.popup-combo-chart-wrapper{
  height: 175px;
}

.popup-combo-chart-wrapper > div{
  min-height: 85px !important;
}

.popup-chart-div .apexcharts-yaxistooltip,
.popup-chart-div .apexcharts-toolbar {
  display: none;
}

.single-emoji-div {
  height: 35px;
  width: 90%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin: 2px;
  justify-content: center;
}
.single-emoji-div i {
  font-size: 20px;
}

.popup-emojis-div {
  display: flex;
}
.calendarChartCss {
  display: flex;
}
.popup-emojis-div .main-div {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pop-main-emojis h5 {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.tooltipContent {
  max-width: 500px;
}
.selectBox {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  max-width: 400px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: 15%;
}

.selectBox .dropdown-input {
  width: 100%;
}

.selectBox .dropdown-input > div {
  border-radius: 24px;
  min-height: 45px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 30px;
}

.progress-value-wrapper {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.map-content {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 9999;
  max-width: 250px;
  background: rgb(20 70 81 / 70%);
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  font-family: "FuturaPT" !important;
}
.map-content p {
  font-size: 13px;
  line-height: 16px;
}

.leaflet-container .export-wrapper {
  position: absolute;
  z-index: 9999;
  bottom: 155px;
  right: 12px;
}

.export-wrapper i {
  font-size: 18px;
}

.leaflet-container .export-wrapper i {
  background-color: #fff;
  border: 1px solid #999;
  padding: 6px 8px;
  border-radius: 50%;
  cursor: pointer;
}

.export-wrapper .iframe-wrapper {
  background: #fff;
  padding: 10px 10px 0 10px;
  position: absolute !important;
  right: 0;
  bottom: 38px;
  border-radius: 8px;
}

.export-wrapper .iframe-wrapper .loader {
  text-align: center;
  display: block;
  padding: 40px;
  z-index: 9;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
}

@media (max-width: 1200px) {
  .footer-sec h2 {
    margin-bottom: 24px;
    font-size: 24px;
  }
  .overview-stats-div {
    padding: 15px;
  }
  .color-bar-main-div div {
    padding: 5px 6px;
    line-height: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .selectBox {
    top: 18%;
  }
  .pagefooter .main-footer {
    padding: 30px;
  }
  .footer-sec:first-child {
    margin-bottom: 20px;
  }
  .export-wrapper .iframe-wrapper {
    right: 45px;
    bottom: -80px;
  }
  .export-wrapper .iframe-wrapper iframe {
    max-width: 230px;
  }
}

@media screen and (max-width: 500px) {
  .color-bar-main-div {
    /* flex-direction: column;
    padding: 15px;
    justify-content: center;
    margin-top: 3px; */
    flex-wrap: wrap;
  }
  .color-bar-main-div .Good,
  .color-bar-main-div .hazardous {
    border-radius: 0;
  }
  .overview-table-wrapper{
    overflow-x: scroll;
  }
  .overview-table-wrapper table{
    width: 100%;
    min-width: 450px;
  }
}
